.container {
    margin-bottom: 10px;
}

html, body{
    margin:0px;
    padding:0px;

}

.nav-ul {
    display: flex;
    background-color: white;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding-left: 0;
    position: fixed;
    margin: 0 auto;
    z-index: 999;
    top: 0;
    width: 100%;
    max-width: 940px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 20px -20px;
    
}

.nav-li {
    list-style: none;
    padding-top: 10px;
    padding-bottom: 10px;
    flex-grow: 1;
    text-align: center;
}

.nav-li[aria-current="page"] {
    list-style: none;
    padding-top: 10px;
    padding-bottom: 10px;
    flex-grow: 1;
    text-align: center;
    background-color: whitesmoke;
}

li:hover{
    background-color: rgb(232, 232, 232);
}

a {
    text-decoration: none;
    color: #000000;
    transition: all 0.3s ease 0s;
}


